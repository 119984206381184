<template>
    <DropdownMenuSubContent
        v-bind="forwarded"
        :class="
            cn(
                'z-50 min-w-32 overflow-hidden border border-gray-200 bg-white text-gray-950 shadow-md divide-solid divide-y data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50',
                props.class
            )
        ">
        <slot />
    </DropdownMenuSubContent>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
    DropdownMenuSubContent,
    type DropdownMenuSubContentEmits,
    type DropdownMenuSubContentProps,
    useForwardPropsEmits,
} from "reka-ui";

const props = defineProps<DropdownMenuSubContentProps & { class?: HTMLAttributes["class"] }>();
const emits = defineEmits<DropdownMenuSubContentEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>
